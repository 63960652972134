<template>
  <div class="container" style="align-items: center;" @touchmove="handleMove" @click="handleMove">
    <div class="guide">

      <div class="step1">
        <div class="num">1</div>
        <div class="step1-text">完善基本信息</div>
      </div>
      <div class="line"></div>
      <div class="step0">
        2
      </div>
    </div>

    <div class="avatar-bg">
      <img :src="image" v-if="image" alt="" class="avatar">
      <img v-else src="../assets/images/default_avatar.png" alt="" class="avatar">
      <div class="upload">
        <span class="icon-upload" style="font-size:0.24rem;color: #131314;"></span>
      </div>
      <h5-cropper :option="option" @getbase64Data="getbase64Data" @getblobData="getBlob" @getFile="getFile">
      </h5-cropper>
    </div>



    <input ref="FileInput" type="file" style="display: none;" @change="onFileSelect" />

    <input class="edit-nick" placeholder="请输入您的昵称" v-model.trim="nickname" @click.stop="" @blur="checkFocusStatus" />

    <div class="footer">
      <a class="link_btn0 btn" @click="commit">下一步</a>
      <div style="height:0.4rem"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from 'jquery'
import H5Cropper from 'vue-cropper-h5'
import MonitorKeyboard from '../config/monitor_keyboard.js'
export default {
  name: '',
  props: {
  },
  components: {
    H5Cropper
  },

  data() {
    return {
      option: {
        cancelButtonBackgroundColor: "#A5A5AD",//取消按钮背景色
        confirmButtonBackgroundColor: "#FFF700",//确定按钮背景色
        cancelButtonTextColor: "#131314",//取消按钮字体色
        confirmButtonTextColor: "#131314",//确定按钮字体色
      }, //配置
      image: null,
      nickname: ''
    }
  },
  watch: {
    nickname(e) {
      this.saveCache()
    }
  },
  beforeDestroy() {
    this.monitorKeyboard.onEnd()
  },
  mounted() {
    this.loadCache();
    this.getKeyboardState();
  },
  methods: {
    handleMove() {
      $('.edit-nick').blur();
      this.timer = setTimeout(() => {
        window.scrollTo(0, 0);
      }, 20);
    },

    checkFocusStatus() {
      if (document.activeElement != $('.edit-nick')) {
        $('.footer').show();
        this.timer = setTimeout(() => {
          window.scrollTo(0, 0);
        }, 20);
      }
    },
    getKeyboardState() {
      this.monitorKeyboard = new MonitorKeyboard();
      this.monitorKeyboard.onStart();

      // 监听虚拟键盘弹出事件
      this.monitorKeyboard.onShow(() => {
        $('.footer').hide();
      })

      //监听键盘收起的事件
      this.monitorKeyboard.onHidden(() => {
        $('.footer').show();
      })
    },
    getbase64Data(data) {
      this.image = data;
    },
    getBlob(blob) {
    },
    getFile(file) {
      var fr = new FileReader();
      var _this = this;
      fr.onload = function () {
        _this.image = fr.result;
      };
      fr.readAsDataURL(file);

      this.uploadFile(file)

    },
    commit() {
      if (this.image == null) {
        this.$toast('请上传头像', require('../assets/images/toast_tip.png'))
        return
      }
      if (this.nickname.length == 0) {
        this.$toast('请输入昵称', require('../assets/images/toast_tip.png'))
        return
      }
      var _this = this
      this.$post(this.$api.userInfo, { nickname: this.nickname }).then(data => {
        global.loginStep = 1.1
        _this.updateLoginStep()
        _this.clearCache()

      })

    },

    loadCache() {
      var obj = localStorage.getItem('profile')
      if (obj) {
        obj = JSON.parse(obj)
        this.image = obj.avatar
        this.nickname = obj.nickname
      }

    },
    saveCache() {
      var obj = {
        avatar: this.image ? this.image : '',
        nickname: this.nickname
      }
      localStorage.setItem('profile', JSON.stringify(obj))
    },
    clearCache() {
      localStorage.removeItem('profile')
    },
    updateLoginStep() {
      this.$post(this.$api.frontData, {
        code: 'profile_completed_step',
        value: 1.1
      }).then(data => {
        this.$router.push('/signup/profile/extra').catch(err=>{})
      })
    },
    onFileSelect(e) {
      this.$toast('', '', true, -1)
      let file = e.target.files[0]
      // console.log(file)

      var fr = new FileReader();
      var _this = this;
      fr.onload = function () {
        _this.image = fr.result;

      };
      fr.readAsDataURL(file);

      this.uploadFile(file)
    },
    uploadFile(file) {
      var _this = this
      this.$get(this.$api.ossFormUpload, {
        type: 'AVATAR',
        file_ext: 'png'
      }).then(data => {
        var url = data.upload_url;
        var strUrl = data.view_url;

        let params = new FormData()

        params.append('OSSAccessKeyId', data.fields.OSSAccessKeyId)
        params.append('Signature', data.fields.Signature)
        params.append('key', data.fields.key)
        params.append('policy', data.fields.policy)
        params.append('file', file, file.name);
        let config = {
          headers: { 'Content-Type': 'multipart/form-data' }

        }
        axios.post(url, params, config).then(res => {
          _this.saveCache()
          this.$post(this.$api.userInfo, { avatar: strUrl }).then(data => {
            global.toast.show = false;
          })
        }).catch(error => {
          global.toast.show = false;
        })
      })
    }
  }
}
</script>
<style lang='scss' scoped>
:deep(.bg) {
  position: fixed;
  top: 0;
  height: 100% !important;
  width: 100%;
  left: 0;
  z-index: 521;
}

:deep(.btndiv) {
  height: 13.333vw;
  color: #fff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 4vw;
  line-height: 13.333vw;
  font-size: 4vw;
  position: absolute !important;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.guide {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.18rem;

  .step0 {
    margin-left: -1px;
    width: 0.44rem;
    height: 0.44rem;
    border-radius: 0.22rem;
    border: solid 0.04rem #74747AFF;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #74747AFF;
    font-size: 0.28rem;
    font-weight: bold;
    font-family: Link;
  }

  .line {
    margin-left: -1px;
    width: 0.6rem;
    height: 0.08rem;
    background: linear-gradient(270deg, #74747A 0%, #FFF700 100%);
  }

  .step1 {

    height: 0.44rem;
    background: #000000;
    box-shadow: 0rem 0.14rem 0.2rem 0rem rgba(255, 247, 0, 0.4), 0rem 0.08rem 0rem -0.02rem #FFF700;
    border-radius: 0.22rem;
    border: 0.04rem solid #FFF700;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.04rem;
    padding-right: 0.16rem;

    .num {
      width: 0.28rem;
      height: 0.28rem;
      background: #FFF700;
      border-radius: 0.14rem;
      color: #131314FF;
      font-size: 0.2rem;
      font-weight: bold;
      line-height: 0.28rem;
      font-family: Link;
    }

    .step1-text {
      color: #FFF700;
      margin-left: 0.06rem;
      font-size: 0.14rem;
      font-weight: bold;
    }
  }
}

.avatar-bg {
  margin-top: 0.74rem;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0.7rem;
  overflow: hidden;
  position: relative;

  .avatar {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    width: 1.4rem;
    height: 1.4rem;
    object-fit: cover;
  }

  .upload {
    height: 0.28rem;
    background-color: #FFF700;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
  }
}

.edit-nick {
  width: 2.79rem;
  height: 0.18rem;
  padding: 0.25rem 0;
  background: #2C2C2E;
  border-radius: 0.16rem;
  border: none;
  text-align: center;
  font-size: 0.18rem;
  margin-top: 0.24rem;
  box-sizing: border-box;
  caret-color: #FF9900FF;
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, rgba(19, 19, 20, 0) 0%, #131314 100%);
}

// ::v-deep .bg{
//   height: 100%;
// }

// ::v-deep .btndiv {
//     height: 13.333vw;
//     color: #fff;
//     justify-content: space-between;
//     display: flex;
//     align-items: center;
//     padding: 0 4vw;
//     line-height: 13.333vw;
//     font-size: 4vw;
//     position: absolute;
//     left: 0;
//     bottom:0;
//     right: 0;
// }
</style>